<template>
  <div class="pa-5">
    <v-icon class="primary--text" size="40px">mdi-cart-outline</v-icon>
    <h3 class="headline mx-auto font-weight-bold">Negocio</h3>
    <p class="text-body-1">Completa la información que se te pide.</p>
    <v-card class="grey lighten-4 rounded-xl mx-1">
      <v-card-title class="">
        <p class="font-weight-bold text-body-1">Información General</p>
        <p class="text-body-1">Completa la información de tu negocio</p>
      </v-card-title>
      <v-card-text>
        <pat-select
          label="Giro"
          placeholder="Selecciona una categoría"
          v-model="business.category"
          :items="categories"
        />
        <br />
        <pat-input
          label="Nombre"
          placeholder="Farmacia la Divina"
          v-model="business.name"
        />
        <br />
        <pat-input
          label="Direccion"
          placeholder="tabasco 134"
          v-model="business.address"
        />
        <br />
        <span class="textlabel--text">¿Tienes otras sucursales?</span>
        <v-radio-group v-model="radioGroup">
          <v-radio label="Sí" value="true" />
          <v-radio label="No" value="false" />
        </v-radio-group>
      </v-card-text>
    </v-card>
    <br />
    <v-card class="grey lighten-4 rounded-xl mx-1">
      <v-card-title class="">
        <p class="font-weight-bold text-body-1">Documentos</p>
        <p class="text-body-1">Agrega tu Aviso de Funcionamiento</p>
      </v-card-title>
      <v-card-text>
        <product-upload-card
          icon="mdi-image-outline"
          text="Da clic aquí para tomar una fotografía o subir un archivo"
        />
      </v-card-text>
    </v-card>
    <br />
    <v-btn
      class="rounded-xl"
      color="secondary"
      block
      tile
      @click="next()"
      large
    >
      Continuar</v-btn
    >
  </div>
</template>

<script>
export default {
  name: "Negocio",
  data() {
    return {
      business: {
        name: "",
        category: "",
        address: "",
      },
      categories: [
        "Farmacia",
        "Tienda de abarrotes",
        "Restaurante",
        "Cafetería",
        "Bar",
        "Otro",
      ],
    };
  },
  methods: {
    // Métodos del componente
    next() {
      this.$router.push({ name: "branches" });
    },
  },
  computed: {
    // Propiedades computadas
  },
  mounted() {
    // Código que se ejecuta al montar el componente
  },
};
</script>

<style scoped>
.negocio {
  /* Estilos del componente */
}
</style>
